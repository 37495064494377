<template>
  <div class="py-md-5 px-2 eos-app__section--fixed-width">
    <div class="col-12">
      <h1>Privacy Policy</h1>
      <div class="white mt-5 pa-8">
        <h3 class="mb-2">Business Privacy Policy</h3>
        <p class="mt-1">
          This privacy policy sets out how InstantAgencytTools.com - a service provided by Teamwork.com and any related products, services, apps or integrations uses and protects any information that you provide when you use our product InstantAgencyTools.com and all of our integrations with third party apps and services.
        </p>
        <p>
          InstantAgencyTools.com is committed to ensuring that your privacy is protected. Any information we seek from you shall only be used in accordance with this privacy policy. This policy is effective from February 2021.
        </p>

        <h3 class="mt-8">What we collect</h3>
        <p class="mt-1">
          We may collect certain information that we need in order to provide you with the business service and products that you receive from us as well as direct marketing information. We will not use your information for any purpose outside those necessary to provide the business service without your consent.
        </p>
        <p>
          The information we will collect may include the following:
          <ul class="mt-3">
            <li>Name and job title</li>
            <li>Business address</li>
            <li>Business telephone number</li>
            <li>Email address</li>
          </ul>
        </p>

        <h3 class="mt-8">What we do with the information we gather</h3>
        <p class="mt-1">
          We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
          <ul class="mt-3">
            <li>Internal record keeping (such as user education, user engagement or user acquisition)</li>
            <li>To improve our products and services</li>
            <li>
              To communicate with individuals using our products based on their usage type and their communication preferences, as described here:
              <ul class="my-3 py-1">
                <li>
                  (Free) Users: From time to time, we may send you technical notices, updates, security alerts, and support and administrative messages and subscription alerts. These emails are not promotional in nature. If you have opted-in to receive email communications from InstantAgencyTools, we reserve the right to send email communications (regarding promotional offers, events, invites) from time to time in accordance with your communications preferences. Users who receive these marketing materials can opt out at any time. If you do not want to receive marketing materials from us, you can opt-out by clearly following the “unsubscribe” instructions at the bottom of any email you receive or by emailing us at <a href="mailto:support@instantagencytools.com" class="text-decoration-none">support@instantagencytools.com</a>.
                </li>
              </ul>
            </li>
            <li>
              From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail.
            </li>
          </ul>
        </p>
        <p class="mt-3">
          Additional Limits on Use of Your Data: Notwithstanding anything else in this Privacy Policy, if you provide the App access to the following types of your Google data, the App’s use of that data will be subject to these additional restrictions:
          <ul class="mt-3">
            <li>
              The App will only use access to read, write, modify or control Gmail message bodies (including attachments), metadata, headers, and settings to provide a web email client that allows users to compose, send, read, and process emails and will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets
            </li>
            <li>The App will not use this Gmail data for serving advertisements</li>
            <li>
              The App will not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for the App’s internal operations and even then only when the data have been aggregated and anonymized
            </li>
            <li>
              The App use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank" class="text-decoration-none">Google API Services User Data Policy</a>, including the Limited Use requirements
            </li>
          </ul>
        </p>

        <p class="mt-5">
          You may choose to restrict the collection or use of your personal information in the following ways:
          <ul class="mt-3">
            <li>
              Whenever you are asked to fill in a form on the website, look for the box that you can  opt out
            </li>
            <li>
              If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at <a href="mailto:support@instantagencytools.com" class="text-decoration-none">support@instantagencytools.com</a>
            </li>
          </ul>
        </p>
        <p>
          We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
        </p>
        <p>
          You may request details of personal information which we hold about you. If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at <a href="mailto:support@instantagencytools.com" class="text-decoration-none">support@instantagencytools.com</a>. We will promptly correct any information found to be incorrect.
        </p>

        <h3 class="mt-8">Security</h3>
        <p class="mt-1">
          We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
        </p>

        <h3 class="mt-8">How we use cookies</h3>
        <p class="mt-1">InstantAgencyTools does not use any cookies at this point. However, we may seek to use them in future versions of the product. At all times this will be compliant with relevant legislation.</p>

        <h3 class="mt-8">Links to other websites</h3>
        <p class="mt-1">
          Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
        </p>

        <h3 class="mt-8">How to contact us</h3>
        <h4 class="mt-3 mb-3"><b>Teamwork.com</b></h4>
        <div class="mb-2"><b>IE:</b>+353 21 4307675</div>
        <div class="mb-2"><b>USA:</b>+1 844 819 8453</div>
        <div class="mb-2"><b>AU:</b>+61 3 7003 8083</div>
        <div class="mb-2"><b>Email:</b>support@instantagencytools.com</div>
        <div><b>Address:</b>Teamwork Campus One, Blackpool Retail Park, Blackpool, Co. Cork, Ireland.</div>

        <v-divider class="my-5" />
        <h4 class="mt-3 mb-3"><b>Data Protection Commissioner (Irl) - www.dataprotection.ie</b></h4>
        <div class="mb-2"><b>Telephone:</b>+353 57 8684800 / +353 (0)761 104 800 / Lo Call No. 1890 252 231</div>
        <div class="mb-2"><b>Email:</b>info@dataprotection.ie</div>
        <div><b>Address:</b>Data Protection Commissioner, Canal House, Station Road, Portarlington, Co. Laois, Ireland.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePrivacyPolicy',
  metaInfo () {
    return {
      title: 'Privacy Policy'
    }
  }
}
</script>
