<template>
  <div class="mt-md-5 px-2 eos-app__section--fixed-width">
    <div class="col-12">
      <h1>About Us</h1>
      <div class="white mt-5 pa-8">
        <p class="mt-0">
          When growing <a href="https://teamwork.grsm.io/instantagencytools" target="_blank">Teamwork</a> from an agency to a software business with 250+ employees we realised we needed a great vision, great people and a great process framework to help us scale. Thus, we adopted the <a href="https://www.eosworldwide.com/what-is-eos" target="_blank">Entrepreneurial Operating System<sup>&reg;</sup></a> and built InstantAgencyTools to drive focus, alignment and transparency.
        </p>
        <p>
          With InstantAgencyTools we want to bring free utilities to help your business break through the ceiling as well. There are 3 key ideas for the tools:
          <ol class="mt-3 ml-5">
            <li>Instant Utility — you can create items instantly, no need to sign up.</li>
            <li>Instant Sharing — you can share links to any items for instant sharing.</li>
            <li>Instant Data Import/Export — You own your data and can import or export it any time instantly.</li>
          </ol>
        </p>
        <p>
          InstantAgencyTools is compatible with the Entrepreneurial Operating System<sup>&reg;</sup> (EOS<sup>&reg;</sup>). If your business isn't running on EOS<sup>&reg;</sup>, you can also utilise InstantAgencyTools to its maximum potential: document and share your vision, align quarterly key priorities, keep track of your business's health and hold productive weekly meetings.
        </p>
        <p>
          If you <v-icon color="red">mdi-heart</v-icon> InstantAgencyTools please spread the word by telling others, include us in your content or tweet about us. Maybe also look at <a href="https://teamwork.grsm.io/instantagencytools" target="_blank">Teamwork</a> (Project and Work Management software suite - integrates with InstantAgencyTools) to see if it's a fit for your business.
        </p>
        <p>
          Please let us know if you have any questions or feedback: <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageAboutUs',
  metaInfo () {
    return {
      title: 'About Us'
    }
  }
}
</script>
