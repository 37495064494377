<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5 pb-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Scorecard</h1>
          <p>
            The InstantAgencyTools Scorecard is a free, great tool to make sure your business is healthy and you're making progress on the right priorities. Numbers create accountability and produce results. Start by defining your key performance (leading) indicators and identify patterns over the course of a quarterly cycle.
          </p>
          <p>
            If you're running your business on the Entrepreneurial Operating System<sup>&reg;</sup> (EOS<sup>&reg;</sup>), see how to build a meaningful Scorecard: <a href="https://www.eosworldwide.com/blog/5-steps-great-scorecard" target="_blank">Scorecard</a>
          </p>
          <p class="text-body-1">
            <i>
              <sup>*</sup>Note: The URL for your InstantAgencyTools Scorecard will always remain the same! Once you share the unique URL of your Scorecard with a team member, they'll be added automatically.
            </i>
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <div class="text-center">
            <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
            <HomeEOSItemBtn
              title="Scorecard"
              class="mb-3"
              :show-loader="isCreatingScorecard"
              :disabled="isCreatingScorecard"
              @click="$emit('create-scorecard')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Track your business's health and make progress on the right priorities</h2>
          <v-img
            src="@/assets/scorecard/weekly.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
          <p>
            Assign ownership for measurables and track them over time. You'll be able to identify patterns and ensure you're on target for your longer-term business objectives. Identify risks in off-track measurables and course-correct quickly or stretch your goals for even greater performance if you achieve your numbers consistently.
          </p>
          <v-img
            src="@/assets/scorecard/monthly.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10 pb-0 pb-md-0">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Find weekly or monthly patterns in your measurables</h2>
          <p>
            Some key performance indicators should be accumulated and reported on monthly. Within the Monthly Scorecard, you can gather your most important business metrics and review them in your monthly business review. By the end of the year, you'll have a great overview of your company's health and performance so you're able to plan ahead.
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Scorecard"
            class="mb-3"
            :show-loader="isCreatingScorecard"
            :disabled="isCreatingScorecard"
            @click="$emit('create-scorecard')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageGuideScorecard',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingScorecard: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Scorecard',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'EOS Scorecard, Free EOS Scorecard, Weekly Scorecard, Monthly Scorecard, Measurables, Key Performance Indicators, Accountability, Ownership'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The InstantAgencyTools Scorecard is a free tool to make sure you\'re making progress on the right priorities and maintain your business\'s health. Start by defining your key performance (leading) indicators and identify performance patterns over the course of a 12-week cycle'
        }
      ]
    }
  }
}
</script>
