var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('div',{staticClass:"white px-5 px-md-10 pt-md-5"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h1',[_vm._v("Weekly Meeting Templates for you to get started with InstantAgencyTools")]),_c('p',[_vm._v(" Increase productivity on your team with the free, structured and timed InstantAgencyTools Weekly Meeting tool. Begin your Weekly Meetings with positive news, a recap on your business health, quarterly priorities and to-dos and focus on solving issues or discussing new opportunities. ")]),_c('p',[_vm._v(" You can get started instantly by utilizing one of the following meeting templates: ")])])])]),_c('div',{staticClass:"white pa-5 pa-md-10"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Keeping the grip on your Marketing Agency")]),_c('p',[_vm._v(" Profitability and accurate planning are important nominators for the success of a Marketing Agency. Get a glimpse on the health of your agency by tracking leading indicators such as conversion metrics, new customer acquisition cost or lifetime value. ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"3"}},[_c('v-img',{staticClass:"elevation-3 mt-5 mx-auto",attrs:{"src":require("@/assets/weekly-meeting-templates/marketing.png"),"contain":"","eager":""}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"secondary","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10', 'marketing')}}},[_vm._v("Use this template")])],1):_vm._e()],1)],1)]}}])})],1)])]),_c('div',{staticClass:"pa-5 pa-md-10"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Design Team")]),_c('p',[_vm._v(" Creativity needs to be profitable. Keep your team on track and your design projects healthy by tracking leading indicators such as response times, alignment of requirements, time budget variance or rework hours. ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"3"}},[_c('v-img',{staticClass:"elevation-3 mt-5 mx-auto",attrs:{"src":require("@/assets/weekly-meeting-templates/design.png"),"contain":"","eager":""}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"secondary","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10', 'design')}}},[_vm._v("Use this template")])],1):_vm._e()],1)],1)]}}])})],1)])]),_c('div',{staticClass:"white pa-5 pa-md-10"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Sales Team")]),_c('p',[_vm._v(" Empower and motivate your Sales team by celebrating small wins and visualizing improvements over time. Determine the success of your sales team by measuring leading indicators such as time to (first) contact, average deal size or conversion rates. ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"3"}},[_c('v-img',{staticClass:"elevation-3 mt-5 mx-auto",attrs:{"src":require("@/assets/weekly-meeting-templates/sales.png"),"contain":"","eager":""}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"secondary","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10', 'sales')}}},[_vm._v("Use this template")])],1):_vm._e()],1)],1)]}}])})],1)])]),_c('div',{staticClass:"pa-5 pa-md-10"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Project Management Team")]),_c('p',[_vm._v(" \"On-time and on-budget\"...the words that make any project manager slaphappy. Keep track of project performance and profitability with leading indicators such as budget variance, project updates provided (stakeholder management), re-work hours or risks. ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"3"}},[_c('v-img',{staticClass:"elevation-3 mt-5 mx-auto",attrs:{"src":require("@/assets/weekly-meeting-templates/project-management.png"),"contain":"","eager":""}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"secondary","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10', 'project-management')}}},[_vm._v("Use this template")])],1):_vm._e()],1)],1)]}}])})],1)])]),_c('div',{staticClass:"white pa-5 pa-md-10"},[_c('div',{staticClass:"eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v("Software / IT Team")]),_c('p',[_vm._v(" Robustness and scalability are essential for the performance of your products. Focus your software / it teams on leading indicators such as reported issues, response times, bugs, or open pull requests and identify risks before they impact your customers. ")]),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":"3"}},[_c('v-img',{staticClass:"elevation-3 mt-5 mx-auto",attrs:{"src":require("@/assets/weekly-meeting-templates/software.png"),"contain":"","eager":""}}),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":""}},[_c('v-btn',{attrs:{"color":"secondary","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10', 'software')}}},[_vm._v("Use this template")])],1):_vm._e()],1)],1)]}}])})],1)])]),_c('div',{staticClass:"pa-5 pa-md-10"},[_c('div',{staticClass:"my-3 eos-app__section--fixed-width"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"title mb-n2 text-uppercase"},[_vm._v("Get Started Free and Create your first")]),_c('HomeEOSItemBtn',{staticClass:"mb-3",attrs:{"title":"Weekly Meeting","show-loader":_vm.isCreatingL10Meeting,"disabled":_vm.isCreatingL10Meeting},on:{"click":function($event){return _vm.$emit('create-l10')}}}),_c('p',{staticClass:"pt-5"},[_vm._v(" Not finding what you're looking for? Email us at "),_c('a',{attrs:{"href":"mailto:support@instantagencytools.com"}},[_vm._v("support@instantagencytools.com")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }