<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      width="320"
      min-height="200"
      class="d-flex flex-column mt-4 mr-4"
      :class="{ 'elevation-4': hover }"
    >
      <v-card-title class="headline" v-html="title" />

      <v-card-subtitle v-if="showDeletedItems">Recently deleted items</v-card-subtitle>
      <v-card-subtitle
        v-else-if="items.length > 0"
        v-html="items.length === 1 ? `You have one ${singularSubtitle}` : `You have ${pluralSubtitle}`"
      />

      <v-card-text>
        <v-list v-if="showDeletedItems" dense class="py-0">
          <v-list-item-group
            v-model="itemsToRestore"
            multiple
          >
            <template v-for="(item, index) in deletedItems">
              <v-list-item
                :key="`trash-${item.id}`"
                class="mx-n4"
                color="primary"
                dense
              >
                <v-list-item-action class="my-0 mr-2">
                  <v-checkbox
                    :input-value="itemsToRestore.includes(index)"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

        <template v-else>
          <v-list v-if="items.length > 0" dense class="py-0">
            <template v-for="item in items">
              <v-list-item
                v-if="!item.isDeleted"
                :key="`${itemPath}-${item.id}`"
                :to="`/${itemPath}/${item.id}`"
                class="mx-n4"
                color="primary"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>{{ icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <span v-else>You don't have {{ singularSubtitle }}. Please click the button below to create a new one.</span>
        </template>
      </v-card-text>
      <v-spacer />

      <v-card-actions>
        <template v-if="showDeletedItems">
          <v-spacer />
          <v-btn depressed small @click="hideDeletedItems">Cancel</v-btn>
          <v-btn depressed small color="success" :disabled="itemsToRestore.length === 0" @click="restore">Restore</v-btn>
        </template>

        <template v-else>
          <v-btn text color="primary" :disabled="btnDisabled" @click="$emit('click')">
          <span v-html="btnText"></span>
            <v-fade-transition v-if="showBtnLoader">
              <AppLoader class="mt-1 ml-3" color="text--disabled" :width="2" :size="16" />
            </v-fade-transition>
          </v-btn>
          <v-spacer />
          <v-tooltip v-if="deletedItems.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                x-small
                v-on="on"
                @click="showDeletedItems = true"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>View Deleted Items</span>
          </v-tooltip>
        </template>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import DataManager from '@/helpers/dataManager'

export default {
  name: 'HomeEOSItems',
  props: {
    collectionName: {
      type: String,
      required: true
    },
    itemPath: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    singularSubtitle: {
      type: String,
      required: true
    },
    pluralSubtitle: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    btnDisabled: {
      type: Boolean,
      default: false
    },
    showBtnLoader: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    deletedItems: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      showDeletedItems: this.items.length === 0 && this.deletedItems.length > 0,
      itemsToRestore: []
    }
  },
  methods: {
    hideDeletedItems () {
      this.showDeletedItems = false
      this.itemsToRestore = []
    },
    restore () {
      const ids = this.itemsToRestore.map(index => this.deletedItems[index].id)
      this.dataManager.restoreObjects(this.collectionName, ids)
      this.hideDeletedItems()
    }
  }
}
</script>
