<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5 pb-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Vision & Plan</h1>
          <p>
            The InstantAgencyTools Vision & Plan is a free, great tool to create business-wide transparency around your long-term focus and tactical priorities. It's divided into two sections: the long-term vision and the tactics to achieve it. The Vision & Plan creates alignment on business strategy and short-term priorities, encourages you to define your business's purpose, niche and target market and to outline any obstacles or untouched opportunities along the way.
          </p>
          <p>
            If you're running your business on the Entrepreneurial Operating System<sup>&reg;</sup> (EOS<sup>&reg;</sup>), the Vision & Plan is compatible with the EOS<sup>&reg;</sup> <a href="https://www.eosworldwide.com/vto" target="_blank">V/TO<sup>&trade;</sup></a>
          </p>
          <p class="text-body-1">
            <i>
              <sup>*</sup>Note: The URL for your InstantAgencyTools Vision & Plan will always remain the same! Once you share the unique URL of your Vision & Plan with a team member, they'll be added automatically.
            </i>
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <div class="text-center">
            <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
            <HomeEOSItemBtn
              title="Vision & Plan"
              class="mb-3"
              :show-loader="isCreatingVto"
              :disabled="isCreatingVto"
              @click="$emit('create-vto')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Create business-wide transparency and alignment around your long-term focus</h2>
          <v-img
            src="@/assets/vision-and-plan/vision.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
          <p>
            Empower and motivate your team by creating a strong vision, purpose and winning strategy for your business. The Vision page outlines your company values, where you're headed in the long-term, which market you're going to win over and how you stand out. It also gives a high-level 3-year outlook into your business.
          </p>
          <v-img
            src="@/assets/vision-and-plan/tactics.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Share your key priorities and get them done</h2>
          <p>
            Generate transparency on your 1-year plan, quarterly key priorities and obstacles in the way. Once communicated, your team members will find ways to contribute to your business strategy and keep their focus on the bigger picture.
          </p>
          <v-img
            src="@/assets/vision-and-plan/permission.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Provide appropriate transparency</h2>
          <p>
            Manage who gets visibility on your Vision and Plan in the permissions settings. You can lock down the tool for your entire company or specific users only.
          </p>
          <v-img
            src="@/assets/vision-and-plan/version.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10 pb-0 pb-md-0">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Review how far you've come</h2>
          <p>
            One day after your latest changes, a new version of your Vision and Plan will be created and old versions can be viewed again to see the progress your team has made.
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Vision & Plan"
            class="mb-3"
            :show-loader="isCreatingVto"
            :disabled="isCreatingVto"
            @click="$emit('create-vto')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageGuideVisionAndPlan',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingVto: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Vision & Plan',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'Vision/Traction Organizer, Free Vision/Traction Organizer, V/TO Issues, Quarterly Rocks, 10-year target, Key priorities, Vision, Business Plan, Transparency'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The InstantAgencyTools Vision & Plan is a free tool to create business-wide transparency and alignment around your long-term focus and tactical priorities. It is divided into two parts: your vision page looks at your company values, your business opportunity, target market and mid-term focus. The tactics page outlines your 1-year plan, quarterly priorities and any obstacles that might get in your way.'
        }
      ]
    }
  }
}
</script>
