<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5 pb-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Weekly Meeting</h1>
          <p>
            Meetings without an agenda, purpose or time restriction can be a waste of time. By introducing structured, timed weekly meetings you'll make your team more productive and ensure the important things get done faster.
          </p>
          <p>
            InstantAgencyTools Weekly Meeting is a free, simple meeting structure. It kicks off with on a positive note and commences with a review of your team's most important kpis and key priorities, focused discussions and a meeting summary. If you're running your company on EOS<sup>&reg;</sup>, you can utilise the InstantAgencyTools Weekly Meeting as your <a href="https://www.eosworldwide.com/download-level10-agenda" target="_blank">Weekly Level 10 Meeting</a>
          </p>
          <p class="text-body-1">
            <i>
              <sup>*</sup>Note: The URL for your InstantAgencyTools Weekly Meeting will always remain the same! Once you share the unique URL of your Weekly Meeting with a team member, they'll be added automatically.
            </i>
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <div class="text-center">
            <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
            <HomeEOSItemBtn
              title="Weekly Meeting"
              class="mb-3"
              :show-loader="isCreatingL10Meeting"
              :disabled="isCreatingL10Meeting"
              @click="$emit('create-l10')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Plan your meeting in advance</h2>
          <p>
            Customise your meeting with your company logo or a motivational image and any important notes. You can randomly assign the scribe and facilitator for the meeting and edit the status for all meeting members (e.g. for absent team members).
          </p>
          <v-img
            src="@/assets/weekly-meeeting/get-started.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Get things done faster with the Teamwork integration</h2>
          <div class="mx-auto mt-5">
            <v-timeline dense align-top style="max-width: 1000px;">
              <v-timeline-item
                fill-dot
                class="mb-12"
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">1</span>
                </template>
                <p class="headline mt-1">To-dos</p>
                <p class="text-body-1 mt-3 mb-5">
                  No issue gets resolved without decisions or accountable to-dos. Make sure to document the important things to get done, assign accountability and track progress.
                </p>
                <v-img
                  src="@/assets/weekly-meeeting/todos.png"
                  class="elevation-3 mr-auto"
                  contain
                  eager
                />
              </v-timeline-item>
              <v-timeline-item
                fill-dot
                class="mb-12"
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">2</span>
                </template>
                <p class="headline mt-1">Sync Options</p>
                <p class="text-body-1 mt-3 mb-5">
                  If you're using the project management software <a href="https://teamwork.grsm.io/instantagencytools" target="_blank">Teamwork</a>, you're able to sync your weekly meeting to-dos automatically into the specific task list of a project you're working on.
                </p>
                <v-img
                  src="@/assets/weekly-meeeting/select-sync-options.png"
                  class="elevation-3"
                  width="100%"
                  max-width="500px"
                  contain
                  eager
                />
              </v-timeline-item>
              <v-timeline-item
                fill-dot
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">3</span>
                </template>
                <p class="headline mt-1">Real-time progress while syncing</p>
                <p class="text-body-1 mt-3 mb-5">
                  Either manually or once you start and end your Weekly Meeting, the to-do sync updates the to-dos between InstantAgencyTools and Teamwork automatically and saves your team unnecessary admin time.
                </p>
                <v-img
                  src="@/assets/weekly-meeeting/sync-todos.png"
                  class="elevation-3"
                  width="100%"
                  max-width="500px"
                  contain
                  eager
                />
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Track your business's health and make progress on the right priorities</h2>
          <p>
            Customise your meeting with your company logo or a motivational image and any important notes. You can randomly assign the scribe and facilitator for the meeting and edit the status for all meeting members (e.g. for absent team members).
          </p>
          <v-img
            src="@/assets/weekly-meeeting/scorecard.png"
            class="elevation-3 my-5 my-md-10 mx-auto"
            contain
            eager
          />
          <p>
            Make sure you're on track with your most important measurables and key priorities. You can drop down any off-track kpis or key priorities as discussion items into the Solving Issues section.
          </p>
          <v-img
            src="@/assets/weekly-meeeting/key-priorities.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12">
          <h2>There's loads of issues to solve...prioritise the important ones</h2>
          <p>
            Spend most of your meeting time on the biggest obstacles that are in your way and any untapped opportunities your team should pursue - the Solving Issues section is the focused space for it. The integrated voting tool lets you prioritise discussion items and make most of your valuable meeting time.
          </p>
          <p>
            The meeting scribe has the ability to take notes on any discussion items while the meeting facilitator makes sure the team stays on track for a solution.
          </p>
          <v-img
            src="@/assets/weekly-meeeting/issues.png"
            class="elevation-3 my-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Conclude with clarity</h2>
          <p>
            Conclude the meeting with buy-in from everyone on the team and assigned to-dos. Your team can rate the meeting on a 1-10 scale and make sure you're improving every week.
          </p>
          <v-img
            src="@/assets/weekly-meeeting/conclude.png"
            class="elevation-3 my-5 my-md-10 mx-auto"
            contain
            eager
          />
          <p>
            As a great reference point (e.g. for absent team members), you're able to send out a Meeting Summary email and set up your next week's meeting in one go!
          </p>
          <v-img
            src="@/assets/weekly-meeeting/summary-email.png"
            class="elevation-3 my-5 my-md-10 mx-auto"
            :max-width="isMobile ? '100%' : '60%'"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Weekly Meeting"
            class="mb-3"
            :show-loader="isCreatingL10Meeting"
            :disabled="isCreatingL10Meeting"
            @click="$emit('create-l10')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageGuideWeeklyMeeting',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingL10Meeting: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Weekly Meeting',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'Weekly Level 10 Meeting, Free Weekly Level 10 Meeting, L10 Meeting, Free Weekly Meeting, Structured Meeting, Timed Meeting, Productive Meeting'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The free structured and timed InstantAgencyTools Weekly Meeting tool helps your team become more productive. The Weekly Meeting starts with positive news, a recap on your business health, quarterly priorities and to-dos and focuses on solving issues or discussing new opportunities.\nWith the ability to sync to-dos in Teamwork\'s project management software, accountabilities are clear and things get done faster'
        }
      ]
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>
