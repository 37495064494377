<template>
  <div>
    <div class="px-5 px-md-10 pt-md-5" :class="{ 'white pb-5': numberOfItems === 0 }">
      <div :class="{ 'eos-app__section--fixed-width pt-0': numberOfItems === 0 }">
        <div class="col-12">
          <!-- Hello Name -->
          <h1>
            <template v-if="loggedInUser">
              <template v-if="loggedInUser.isAnonymous">Hello, Guest User!</template>
              <template v-else>Hello, {{ loggedInUser.displayName }}!</template>
            </template>
            <template v-else>
              Introducing InstantAgencyTools - the free <br/> toolset to help you organize your business
            </template>
          </h1>

          <!-- Welcome Message -->
          <div v-if="numberOfItems === 0">
            <p class="eos-app__home-page__title">
              Run more productive meetings and align your teams behind a shared vision. Build and visualize the organizational structure that fits your business. Track your core kpis and see how you're doing over time. All in one place.
            </p>
            <p class="eos-app__home-page__title">
              This looks like your first time here...
            </p>

            <p class="eos-app__home-page__title">
              You can get started instantly by creating your first free Weekly Meeting (recommended), Vision and Plan, Organisational Chart or Scorecard. Or keep going and learn more about the tools.
            </p>

            <div class="mt-6 d-flex justify-center vframe">
              <iframe width="560" height="315" class="video" src="https://www.youtube.com/embed/u47cgdlfNu4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <template v-else>
            <p>
              Welcome back.
              <template v-if="numberOfItems === 1">
                You already have an item saved so can pick up where you left off. Enjoy!
              </template>
              <template v-else>
                You already have some items so can pick up where you left off.
              </template>
            </p>
          </template>
          <v-img
            v-show="isTabOrDesktop"
            src="@/assets/images/homeback.png"
            contain
            max-width="350px"
            class="position-absolute"
            :style="numberOfItems === 0 ? 'top: 341px; left: 65%;' : 'bottom: 0px; left: 70%;'"
          />
        </div>
      </div>
    </div>

    <div v-if="numberOfItems > 0" class="col-12">
      <div class="px-5 px-md-10 d-block">
        <!-- New Meeting -->
        <HomeEOSItemBtn
          v-if="loggedInUserData.linkedLevel10s.length === 0 && trashedItems.linkedLevel10s.length === 0"
          title="Weekly Meeting"
          :dark="numberOfItems === 0"
          :depressed="numberOfItems > 0"
          :small="numberOfItems > 0"
          :show-loader="isCreatingL10Meeting"
          :disabled="isLoading"
          @click="$emit('create-l10')"
        />

        <!-- New Key Priorities -->
        <HomeEOSItemBtn
          v-if="loggedInUserData.linkedKeyPriorities.length === 0 && trashedItems.linkedKeyPriorities.length === 0"
          title="Key Priorities"
          :dark="numberOfItems === 0"
          :depressed="numberOfItems > 0"
          :small="numberOfItems > 0"
          :show-loader="isCreatingKeyPriorities"
          :disabled="isLoading"
          @click="$emit('create-key-priorities')"
        />

        <!-- New VTO -->
        <HomeEOSItemBtn
          v-if="loggedInUserData.linkedVTOs.length === 0 && trashedItems.linkedVTOs.length === 0"
          title="Vision & Plan"
          :dark="numberOfItems === 0"
          :depressed="numberOfItems > 0"
          :small="numberOfItems > 0"
          :show-loader="isCreatingVto"
          :disabled="isLoading"
          @click="$emit('create-vto')"
        />

        <!-- New Chart -->
        <HomeEOSItemBtn
          v-if="loggedInUserData.linkedCharts.length === 0 && trashedItems.linkedCharts.length === 0"
          title="Organisational Chart"
          :dark="numberOfItems === 0"
          :depressed="numberOfItems > 0"
          :small="numberOfItems > 0"
          :show-loader="isCreatingAccountabilityChart"
          :disabled="isLoading"
          @click="$emit('create-acc-chart')"
        />

        <!-- New Scorecard -->
        <HomeEOSItemBtn
          v-if="loggedInUserData.linkedScorecards.length === 0 && trashedItems.linkedScorecards.length === 0"
          title="Scorecard"
          :dark="numberOfItems === 0"
          :depressed="numberOfItems > 0"
          :small="numberOfItems > 0"
          :show-loader="isCreatingScorecard"
          :disabled="isLoading"
          @click="$emit('create-scorecard')"
        />
      </div>

      <div class="d-flex flex-wrap px-5 px-md-10 mt-3">
        <!-- Meetings -->
        <HomeEOSItems
          v-if="loggedInUserData.linkedLevel10s.length > 0 || trashedItems.linkedLevel10s.length > 0"
          collection-name="level10s"
          icon="mdi-calendar"
          item-path="weeklymeetings"
          title="Weekly Meetings"
          singular-subtitle="weekly meeting"
          plural-subtitle="weekly meetings"
          btn-text="New Weekly Meeting"
          :show-btn-loader="isCreatingL10Meeting"
          :btn-disabled="isLoading"
          :items="loggedInUserData.linkedLevel10s"
          :deleted-items="trashedItems.linkedLevel10s"
          @click="$emit('create-l10')"
        />

        <!-- Key Priorities -->
        <HomeEOSItems
          v-if="loggedInUserData.linkedKeyPriorities.length > 0 || trashedItems.linkedKeyPriorities.length > 0"
          collection-name="keypriorities"
          icon="mdi-bullseye-arrow"
          item-path="keypriorities"
          title="Key Priorities"
          singular-subtitle="key priorities"
          plural-subtitle="key priorities"
          btn-text="New Key Priorities"
          :show-btn-loader="isCreatingKeyPriorities"
          :btn-disabled="isLoading"
          :items="loggedInUserData.linkedKeyPriorities"
          :deleted-items="trashedItems.linkedKeyPriorities"
          @click="$emit('create-key-priorities')"
        />

        <!-- VTOs -->
        <HomeEOSItems
          v-if="loggedInUserData.linkedVTOs.length > 0 || trashedItems.linkedVTOs.length > 0"
          collection-name="vtos"
          icon="mdi-binoculars"
          item-path="visionandplans"
          title="Vision & Plans"
          singular-subtitle="vision and plan"
          plural-subtitle="vision and plans"
          btn-text="New Vision & Plan"
          :show-btn-loader="isCreatingVto"
          :btn-disabled="isLoading"
          :items="loggedInUserData.linkedVTOs"
          :deleted-items="trashedItems.linkedVTOs"
          @click="$emit('create-vto')"
        />

        <!-- Charts -->
        <HomeEOSItems
          v-if="loggedInUserData.linkedCharts.length > 0 || trashedItems.linkedCharts.length > 0"
          collection-name="charts"
          icon="mdi-family-tree"
          item-path="organisationalcharts"
          title="Organisational Charts"
          singular-subtitle="organisational chart"
          plural-subtitle="organisational charts"
          btn-text="New Organisational Chart"
          :show-btn-loader="isCreatingAccountabilityChart"
          :btn-disabled="isLoading"
          :items="loggedInUserData.linkedCharts"
          :deleted-items="trashedItems.linkedCharts"
          @click="$emit('create-acc-chart')"
        />

        <!-- Scorecards -->
        <HomeEOSItems
          v-if="loggedInUserData.linkedScorecards.length > 0 || trashedItems.linkedScorecards.length > 0"
          collection-name="scorecards"
          icon="mdi-table-large"
          item-path="kpiscorecards"
          title="Scorecards"
          singular-subtitle="scorecard"
          plural-subtitle="scorecards"
          btn-text="New Scorecard"
          :show-btn-loader="isCreatingScorecard"
          :btn-disabled="isLoading"
          :items="loggedInUserData.linkedScorecards"
          :deleted-items="trashedItems.linkedScorecards"
          @click="$emit('create-scorecard')"
        />
      </div>
    </div>

    <div v-else>
      <!-- Weekly Meeting -->
      <div id="weekly-meeting" class="pa-5 pa-md-10">
        <HomeEOSSection
          title="Weekly Meeting"
          subtitle="Make meetings productive, save time and get the important things done faster"
          :image="require('@/assets/illustrations/meeting.svg')"
        >
          <p>
            Meandering meetings are no more. Let the Weekly Meeting tool do the busy work and preparation. It's a timed weekly meeting with an effective structure to keep your team on the same page: positive news, a recap on your business health, quarterly goals and to-dos, and the focus on discussing the important topics. The Weekly Meeting URL always remains the same. Bookmarked once, never lost.
          </p>

          <div slot="button" class="d-flex flex-column flex-sm-row align-center">
            <HomeEOSItemBtn
              title="Weekly Meeting"
              margin-class="mr-5 mt-5"
              depressed
              :show-loader="isCreatingL10Meeting"
              :disabled="isLoading"
              @click="$emit('create-l10')"
            />

            <v-btn large outlined color="secondary" class="mt-5" :to="{ path: '/weeklymeeting' }">Learn more</v-btn>
          </div>
        </HomeEOSSection>
      </div>

      <!-- Org Chart -->
      <div id="org-chart" class="white pa-5 pa-md-10 position-relative">
        <HomeEOSSection
          title="Organisational Chart"
          subtitle="Visualize the organizational chart that's right for your business"
          class="flex-row-reverse"
          :image="require('@/assets/illustrations/org-chart.svg')"
        >
          <p>
            Share with your team the organizational structure that creates accountability and transparency...so everyone knows their way around your business. Make sure you have the right people sitting in the right seats and outline their responsibilities. No whiteboard or (painful) mind-mapping tools required...simply create seats and drag and drop them into the right position.
          </p>

          <div slot="button" class="d-flex flex-column flex-sm-row align-center">
            <HomeEOSItemBtn
              title="Organisational Chart"
              margin-class="mr-5 mt-5"
              depressed
              :show-loader="isCreatingAccountabilityChart"
              :disabled="isLoading"
              @click="$emit('create-acc-chart')"
            />

            <v-btn large outlined color="secondary" class="mt-5" :to="{ path: '/organisationalchart' }">Learn more</v-btn>
          </div>
        </HomeEOSSection>
        <v-img
          v-show="isTabOrDesktop"
          src="@/assets/illustrations/dot-rect.svg"
          contain
          class="position-absolute"
          style="top: -40px; right: 5%;"
        />
      </div>

      <!-- Scorecard -->
      <div id="scorecard" class="pa-5 pa-md-10 position-relative">
        <HomeEOSSection
          title="Scorecard"
          subtitle="Track your business's health and make progress on the right priorities"
          :image="require('@/assets/illustrations/scorecard.svg')"
        >
          <p>
            How do you know your business is healthy? By measuring your business's core kpis you ensure you're making progress in the right direction. Track the most important leading indicators for your business and identify performance patterns over the course of a quarterly cycle. Interpret trends and tackle risks before they grow into a real obstacle for your team.
          </p>

          <div slot="button" class="d-flex flex-column flex-sm-row align-center">
            <HomeEOSItemBtn
              title="Scorecard"
              margin-class="mr-5 mt-5"
              depressed
              :show-loader="isCreatingScorecard"
              :disabled="isLoading"
              @click="$emit('create-scorecard')"
            />

            <v-btn large outlined color="secondary" class="mt-5" :to="{ path: '/kpiscorecard' }">Learn more</v-btn>
          </div>
        </HomeEOSSection>
        <v-img
          v-show="isTabOrDesktop"
          src="@/assets/illustrations/dot-square.svg"
          contain
          class="position-absolute"
          style="bottom: -70px; left: 34%;"
        />
      </div>

      <!-- Vision & Plan -->
      <div id="vision-and-plan" class="white pa-5 pa-md-10">
        <HomeEOSSection
          title="Vision + Plan"
          subtitle="Create business-wide transparency and alignment around your long-term focus"
          class="flex-row-reverse"
          :image="require('@/assets/illustrations/plan.svg')"
        >
          <p>
            Transparency avoids miss-communication and creates alignment. Build your two-page Vision + Plan that's focused on success: your vision page looks at your company values, your niche, target market and mid-term goals. The tactics page outlines your 1-year plan, quarterly goals and any obstacles in your way. Keep your vision in sight, set your key priorities and communicate both effectively.
          </p>

          <div slot="button" class="d-flex flex-column flex-sm-row align-center">
            <HomeEOSItemBtn
              title="Vision + Plan"
              margin-class="mr-5 mt-5"
              depressed
              :show-loader="isCreatingVto"
              :disabled="isLoading"
              @click="$emit('create-vto')"
            />

            <v-btn large outlined color="secondary" class="mt-5" :to="{ path: '/visionandplan' }">Learn more</v-btn>
          </div>
        </HomeEOSSection>
      </div>

      <!-- Still wondering... -->
      <div id="scorecard" class="pa-5 pa-md-10">
        <v-row class="eos-app__section--fixed-width">
          <v-col xs="12" md="12" class="text-center">
            <h2>Still wondering if FREE is worth it?</h2>

            <p>InstantAgencyTools helps your team get organized and save time.</p>
          </v-col>
          <v-col xs="12" md="9">
            <p>How? Through...</p>

            <div>
              <p class="mb-0">
                <v-icon color="secondary" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                <span>
                  One vision = less debate around direction and stronger decision-making
                </span>
              </p>
              <p class="mt-1">
                <v-icon color="secondary" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                <span class="ml-1">
                  Productive weekly meetings = no more running over through structured, productive and timed meetings, plus automatic meeting preparation
                </span>
              </p>
              <p class="my-1">
                <v-icon color="secondary" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                <span class="ml-1">
                  Knowing who's who and what they're accountable for with the Organisational Chart
                </span>
              </p>
              <p class="my-1">
                <v-icon color="secondary" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                <span class="ml-1">
                  Visualising how your business is doing and pre-empting issues with Scorecards
                </span>
              </p>
              <p class="mt-1">
                <v-icon color="secondary">mdi-checkbox-marked-circle</v-icon>
                <span class="ml-1">
                  (Optional) automatic sync of to-dos into Teamwork= less admin work and automatic meeting preparation
                </span>
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row class="eos-app__section--fixed-width">
          <v-col xs="12" md="9">
            <HomeEOSItemBtn
              title="Weekly Meeting"
              margin-class="mr-5 mt-3"
              depressed
              :show-loader="isCreatingL10Meeting"
              :disabled="isLoading"
              @click="$emit('create-l10')"
            />

            <HomeEOSItemBtn
              title="Organisational Chart"
              margin-class="mr-5 mt-3"
              depressed
              :show-loader="isCreatingAccountabilityChart"
              :disabled="isLoading"
              @click="$emit('create-acc-chart')"
            />

            <HomeEOSItemBtn
              title="Scorecard"
              margin-class="mr-5 mt-3"
              depressed
              :show-loader="isCreatingScorecard"
              :disabled="isLoading"
              @click="$emit('create-scorecard')"
            />

            <HomeEOSItemBtn
              title="Vision + Plan"
              margin-class="mr-5 mt-3"
              depressed
              :show-loader="isCreatingVto"
              :disabled="isLoading"
              @click="$emit('create-vto')"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DataManager from '@/helpers/dataManager'
import HomeEOSItems from '@/components/home/HomeEOSItems'
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'
import HomeEOSSection from '@/components/home/HomeEOSSection'

export default {
  name: 'PageHome',
  components: {
    HomeEOSItems,
    HomeEOSItemBtn,
    HomeEOSSection
  },
  props: {
    numberOfItems: {
      type: Number,
      default: 0
    },
    isCreatingVto: {
      type: Boolean,
      default: false
    },
    isCreatingL10Meeting: {
      type: Boolean,
      default: false
    },
    isCreatingAccountabilityChart: {
      type: Boolean,
      default: false
    },
    isCreatingKeyPriorities: {
      type: Boolean,
      default: false
    },
    isCreatingScorecard: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Home'
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'loggedInUserData', 'trashedItems']),
    isLoading () {
      return this.isCreatingL10Meeting || this.isCreatingAccountabilityChart || this.isCreatingScorecard || this.isCreatingKeyPriorities
    },
    isTabOrDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  created () {
    const params = this.$route.params
    if (params.removeEntity && params.removeId) {
      const dataManager = new DataManager()
      dataManager.updateUserReferenceToObject(params.removeEntity, params.removeId)
    }
  }
}
</script>

<style type="scss" scoped>
p.eos-app__home-page__title {
  max-width: 700px;
  position: relative;
  z-index: 1;
}

.vframe {
  max-width: 600px;
  .video {
    max-width: 100%;
  }
}
</style>
