<template>
  <v-row class="position-relative eos-app__section--fixed-width">
    <v-col xs="12" md="6">
      <v-img
        :src="image"
        contain
        class="mx-auto"
        :class="[`elevation-${imageElevation}`]"
        :max-height="imageMaxHeight"
        :width="imageWidth"
      />
    </v-col>
    <v-col xs="12" md="6">
      <h1 v-if="title" v-html="title"></h1>
      <h2 v-if="subtitle" class="headline font-weight-medium pb-3" v-html="subtitle"></h2>
      <slot></slot>
      <slot name="button"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HomeEOSSection',
  props: {
    image: {
      type: String,
      default: ''
    },
    imageWidth: {
      type: [String, Number],
      default: '90%'
    },
    imageMaxHeight: {
      type: [String, Number],
      default: '375px'
    },
    imageElevation: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>
