var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{staticClass:"mt-2 text-wrap",class:[
    {
      'eos-item__btn--disabled': _vm.disabled,
      'mr-2': _vm.small && !_vm.marginClass,
      'mx-auto mt-5': !_vm.small && !_vm.marginClass
    },
    _vm.marginClass
  ],attrs:{"color":"secondary","dark":!_vm.small,"depressed":_vm.depressed,"large":!_vm.small,"small":_vm.small},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]),_c('span',{staticClass:"pr-1",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.showLoader)?_c('v-fade-transition',[_c('AppLoader',{staticClass:"mt-1 ml-3",attrs:{"color":"text--disabled","width":2,"size":16}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }