<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5 pb-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Organisational Chart</h1>
          <p>
            Ensure your whole team knows what everyone is accountable for as part of their function in the free InstantAgencyTools Organisational Chart. It's a simple, yet powerful tool to ensure accountabilities are clear and transparency is shared throughout your business.
          </p>
          <p>
            The InstantAgencyTools Organisation Chart is compatible with the EOS<sup>&reg;</sup>: <a href="https://www.eosworldwide.com/blog/accountability-chart-or-organizational-chart" target="_blank">Accountability Chart<sup>&trade;</sup></a>
          </p>
          <p class="text-body-1">
            <i>
              <sup>*</sup>Note: The URL for your InstantAgencyTools Organisational Chart will always remain the same! Once you share the unique URL of your Organisational Chart with a team member, they'll be added automatically.
            </i>
          </p>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <div class="text-center">
            <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
            <HomeEOSItemBtn
              title="Organisational Chart"
              class="mb-3"
              :show-loader="isCreatingAccountabilityChart"
              :disabled="isCreatingAccountabilityChart"
              @click="$emit('create-acc-chart')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Ensure clear accountabilities</h2>
          <p>
            Define every seat in your company with detail and ensure roles and accountabilities are well established. The Organisational Chart is a great tool to share with your entire team and makes sure everyone knows their way around your organisation from the day one.
          </p>
          <v-img
            src="@/assets/org-chart/overview.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Redo / Undo any changes</h2>
          <p>
            Made a mistake? No problem...simply revert your actions through the undo functionality or skip back and redo your previous action.
          </p>
          <v-img
            src="@/assets/org-chart/undo-redo.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            max-width="500px"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Tag and filter important areas</h2>
          <p>
            Highlight certain seats in your organization through the simple tagging functionality. You can set a primary tag and customize the seat background and text colour. You only want to see specific tags? Utilize the filter option and recognize the seats most relevant to you.
          </p>
          <v-img
            src="@/assets/org-chart/tag-filters.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            max-width="300px"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Provide appropriate transparency</h2>
          <p>
            You're able to manage permissions and provide access either for your entire business domain or specific users only. Once you share the unique URL of your Organisational Chart with a team member, they'll be added to it automatically.
          </p>
          <v-img
            src="@/assets/org-chart/permission.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Simply drag and drop seats</h2>
          <p>
            Simply re-organise the seats in your Organisational Chart by dragging and dropping them into the right spot.
          </p>
          <v-img
            src="@/assets/org-chart/drag-and-drop.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Easily export to pdf and print your organizational structure</h2>
          <p>
            With one click, easily export your entire organizational chart into pdf. You can print certain sections or the entire chart from your within your pdf viewer.
          </p>
          <v-img
            src="@/assets/org-chart/export-to-pdf.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            max-width="500px"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Organisational Chart"
            class="mb-3"
            :show-loader="isCreatingAccountabilityChart"
            :disabled="isCreatingAccountabilityChart"
            @click="$emit('create-acc-chart')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageGuideOrganisationalChart',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingAccountabilityChart: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Organisational Chart',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'Accountability Chart, Organisational Chart, Free Accountability Chart, Free Organisational Chart, Transparency, Accountability'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The InstantAgencyTools Organisational Chart is a free tool to share with your entire team and make sure everyone knows their way around your organisation. It outlines accountabilities of each seat and highlights empty seats in your organisation that need to be filled.'
        }
      ]
    }
  }
}
</script>
