<template>
  <v-btn
    color="secondary"
    :dark="!small"
    :depressed="depressed"
    :large="!small"
    :small="small"
    class="mt-2 text-wrap"
    :class="[
      {
        'eos-item__btn--disabled': disabled,
        'mr-2': small && !marginClass,
        'mx-auto mt-5': !small && !marginClass
      },
      marginClass
    ]"
    @click="$emit('click')"
  >
    <v-icon left>{{ icon }}</v-icon>
    <span v-html="title" class="pr-1"></span>
    <v-fade-transition v-if="showLoader">
      <AppLoader class="mt-1 ml-3" color="text--disabled" :width="2" :size="16" />
    </v-fade-transition>
  </v-btn>
</template>

<script>
export default {
  name: 'HomeEOSItemBtn',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    title: {
      type: String,
      default: 'Add'
    },
    marginClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-item__btn--disabled {
  pointer-events: none;
}
</style>
