<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Weekly Meeting Templates for you to get started with InstantAgencyTools</h1>
          <p>
            Increase productivity on your team with the free, structured and timed InstantAgencyTools Weekly Meeting tool. Begin your Weekly Meetings with positive news, a recap on your business health, quarterly priorities and to-dos and focus on solving issues or discussing new opportunities.
          </p>
          <p>
            You can get started instantly by utilizing one of the following meeting templates:
          </p>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Keeping the grip on your Marketing Agency</h2>
          <p>
            Profitability and accurate planning are important nominators for the success of a Marketing Agency. Get a glimpse on the health of your agency by tracking leading indicators such as conversion metrics, new customer acquisition cost or lifetime value.
          </p>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card elevation="3">
                <v-img
                  src="@/assets/weekly-meeting-templates/marketing.png"
                  class="elevation-3 mt-5 mx-auto"
                  contain
                  eager
                />

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                  >
                    <v-btn
                      color="secondary"
                      :show-loader="isCreatingL10Meeting"
                      :disabled="isCreatingL10Meeting"
                      @click="$emit('create-l10', 'marketing')"
                    >Use this template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Design Team</h2>
          <p>
            Creativity needs to be profitable. Keep your team on track and your design projects healthy by tracking leading indicators such as response times, alignment of requirements, time budget variance or rework hours.
          </p>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card elevation="3">
                <v-img
                  src="@/assets/weekly-meeting-templates/design.png"
                  class="elevation-3 mt-5 mx-auto"
                  contain
                  eager
                />

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                  >
                    <v-btn
                      color="secondary"
                      :show-loader="isCreatingL10Meeting"
                      :disabled="isCreatingL10Meeting"
                      @click="$emit('create-l10', 'design')"
                    >Use this template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Sales Team</h2>
          <p>
            Empower and motivate your Sales team by celebrating small wins and visualizing improvements over time. Determine the success of your sales team by measuring leading indicators such as time to (first) contact, average deal size or conversion rates.
          </p>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card elevation="3">
                <v-img
                  src="@/assets/weekly-meeting-templates/sales.png"
                  class="elevation-3 mt-5 mx-auto"
                  contain
                  eager
                />

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                  >
                    <v-btn
                      color="secondary"
                      :show-loader="isCreatingL10Meeting"
                      :disabled="isCreatingL10Meeting"
                      @click="$emit('create-l10', 'sales')"
                    >Use this template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Project Management Team</h2>
          <p>
            "On-time and on-budget"...the words that make any project manager slaphappy. Keep track of project performance and profitability with leading indicators such as budget variance, project updates provided (stakeholder management), re-work hours or risks.
          </p>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card elevation="3">
                <v-img
                  src="@/assets/weekly-meeting-templates/project-management.png"
                  class="elevation-3 mt-5 mx-auto"
                  contain
                  eager
                />

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                  >
                    <v-btn
                      color="secondary"
                      :show-loader="isCreatingL10Meeting"
                      :disabled="isCreatingL10Meeting"
                      @click="$emit('create-l10', 'project-management')"
                    >Use this template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Software / IT Team</h2>
          <p>
            Robustness and scalability are essential for the performance of your products. Focus your software / it teams on leading indicators such as reported issues, response times, bugs, or open pull requests and identify risks before they impact your customers.
          </p>
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card elevation="3">
                <v-img
                  src="@/assets/weekly-meeting-templates/software.png"
                  class="elevation-3 mt-5 mx-auto"
                  contain
                  eager
                />

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                  >
                    <v-btn
                      color="secondary"
                      :show-loader="isCreatingL10Meeting"
                      :disabled="isCreatingL10Meeting"
                      @click="$emit('create-l10', 'software')"
                    >Use this template</v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Weekly Meeting"
            class="mb-3"
            :show-loader="isCreatingL10Meeting"
            :disabled="isCreatingL10Meeting"
            @click="$emit('create-l10')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageTemplates',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingL10Meeting: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Templates',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'Free Weekly Meeting template, Weekly Meeting template, Meeting planning, Agency Weekly Meeting, Marketing Team Weekly Meeting, Sales Team Weekly Meeting, Software Team Weekly Meeting, Design Team Weekly Meeting'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The free structured and timed InstantAgencyTools Weekly Meeting tool helps your team become more productive. The Weekly Meeting starts with positive news, a recap on your business health, quarterly priorities and to-dos and focuses on solving issues or discussing new opportunities. You can get started instantly by utilizing one of the free meeting templates we provide for marketing agencies, design teams, sales teams, project management teams or software teams.'
        }
      ]
    }
  }
}
</script>
