<template>
  <v-layout column fill-height>
    <div class="white px-5 px-md-10 pt-md-5 pb-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h1>Bring your vision to the ground with key priorities</h1>
          <p>
            You have your vision defined? It's time to make it real. Develop, track and manage the key priorities that'll make the most impact over the next 90 days (recommended) for your business or team within the InstantAgencyTools Key Priorities tool.
          </p>
          <p>
            Whether you're working with rocks as part of the Entrepreneurial Operating System (EOS<sup>&reg;</sup>), priorities through Scaling Up or Objectives and Key Results (OKRs), you can apply this tool to manage all your business goals.
          </p>
          <v-img
            src="@/assets/key-priorities/list.png"
            class="elevation-3 mt-5 mt-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <div class="text-center">
            <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
            <HomeEOSItemBtn
              title="Key Priorities"
              class="mb-3"
              :show-loader="isCreatingKeyPriorities"
              :disabled="isCreatingKeyPriorities"
              @click="$emit('create-key-priorities')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Share rocks with the Weekly Meetings and Vision & Plans your part of</h2>
          <p>
            Easily manage and sync your Key priorities with the InstantAgencyTools <router-link to="/weeklymeeting">Weekly Meetings</router-link> and <router-link to="/visionandplan">Vision and Plan</router-link> you're already part of. Any changes you make are reflected throughout your synced tools. By reporting on key priorities weekly, you're making sure your team works on the right things.
          </p>
          <v-img
            src="@/assets/key-priorities/select-meetings.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
          <v-img
            src="@/assets/key-priorities/link-from-vto.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Break down your priorities into small deliverables</h2>
          <p>
            Every big priority has multiple stakeholders. Add and delegate deliverables to everyone involved in achieving your key priority. You can assign out the small milestones that need to be hit along the way and track progress over time.
          </p>
          <v-img
            src="@/assets/key-priorities/deliverables.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>See progress and completion rates at one glance</h2>
          <p>
            See how your company, functional (=departmental), team or personal priorities are progressing. The ratio of on-track vs. off-track key priorities helps you identify risks early and course-correct accordingly. At the end of each key priorities cycle, asses how you did as a company and team.
          </p>
          <v-img
            src="@/assets/key-priorities/progress.png"
            class="elevation-3 mt-5 my-md-10 mx-auto"
            contain
            eager
          />
        </div>
      </div>
    </div>

    <div class="pa-5 pa-md-10">
      <div class="eos-app__section--fixed-width">
        <div class="col-12">
          <h2>Jump into Execution Mode with the neat integration with Teamwork</h2>
          <div class="mx-auto mt-5">
            <v-timeline dense align-top style="max-width: 1000px;">
              <v-timeline-item
                fill-dot
                class="mb-12"
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">1</span>
                </template>
                <p class="headline mt-1">Turn on sync</p>
                <p class="text-body-1 mt-3 mb-5">
                  Turn on the sync with Teamwork and log into your Teamwork account.
                </p>
                <v-img
                  src="@/assets/key-priorities/sync-dialog.png"
                  class="elevation-3 mr-auto"
                  max-width="300px"
                  contain
                  eager
                />
              </v-timeline-item>
              <v-timeline-item
                fill-dot
                class="mb-12"
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">2</span>
                </template>
                <p class="headline mt-1">Sync options</p>
                <p class="text-body-1 mt-3 mb-5">
                  You're able to sync your key priorities automatically into the specific task list of any project in Teamwork you're part of.
                </p>
                <v-img
                  src="@/assets/key-priorities/sync-options.png"
                  class="elevation-3"
                  width="100%"
                  contain
                  eager
                />
              </v-timeline-item>
              <v-timeline-item
                fill-dot
                color="secondary"
              >
                <template v-slot:icon>
                  <span class="white--text">3</span>
                </template>
                <p class="headline mt-1">Easy access </p>
                <p class="text-body-1 mt-3 mb-5">
                  Once synced, you'll see the Teamwork icon highlighted on your key priority in InstantAgencyTools. Within one click, it directs you straight into the key priority task within Teamwork.
                </p>
                <v-img
                  src="@/assets/key-priorities/link-to-teamwork-task.png"
                  class="elevation-3"
                  width="100%"
                  max-width="500px"
                  contain
                  eager
                />
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>
      </div>
    </div>

    <div class="white pa-5 pa-md-10">
      <div class="my-3 eos-app__section--fixed-width">
        <div class="col-12 text-center">
          <p class="title mb-n2 text-uppercase">Get Started Free and Create your first</p>
          <HomeEOSItemBtn
            title="Key Priorities"
            class="mb-3"
            :show-loader="isCreatingKeyPriorities"
            :disabled="isCreatingKeyPriorities"
            @click="$emit('create-key-priorities')"
          />
          <p class="pt-5">
            Not finding what you're looking for? Email us at <a href="mailto:support@instantagencytools.com">support@instantagencytools.com</a>
          </p>
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import HomeEOSItemBtn from '@/components/home/HomeEOSItemBtn'

export default {
  name: 'PageGuideKeyPriorities',
  components: {
    HomeEOSItemBtn
  },
  props: {
    isCreatingKeyPriorities: {
      type: Boolean,
      default: false
    }
  },
  metaInfo () {
    return {
      title: 'Key Priorities',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'EOS Rocks, Quarterly goals, Quarterly targets, Key business priorities, Business goals, Business planning, Strategy planning, Objectives and Key results, Free business objective tool, Free objectives and key results tool, Free business planning tool, Free strategy planning tool'
        },
        {
          vmid: 'description',
          name: 'description',
          content: 'The free InstantAgencyTools Key Priorities tool helps you track and manage your business goals and priorities. See progress and completion rates of all your key priorities at one glance. You\'re able to integrate your key priorities into your Vision and Plan as well as your Weekly Meetings...all tools sync up seamlessly.Whether you\'re setting rocks as part of the Entrepreneurial Operating System (EOS®), priorities through Scaling Up or use Objectives and Key Results (OKRs), you can apply this tool to manage all your business goals.'
        }
      ]
    }
  }
}
</script>
