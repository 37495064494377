<template>
  <div class="mt-5 px-2 eos-app__section--fixed-width">
    <div class="col-12">
      <h1>Smart questions people like to ask before starting to use InstantAgencyTools:</h1>
      <div class="mt-5 eos-faqs">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>What’s InstantAgencyTools all about?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              We love agencies at
              <a
                target="_blank"
                href="https://teamwork.grsm.io/instantagencytools"
              >Teamwork</a>
              and want to bring some utilities to help your business become more aligned, focused and transparent. There are 3 key ideas for InstantAgencyTools:
              <br />
              <br />
              <ol>
                <li>
                  <strong>Instant Utility</strong> — you can create items instantly, no need to sign up.
                </li>
                <li>
                  <strong>Instant Sharing</strong> — you can share links to any items for instant sharing.
                </li>
                <li>
                  <strong>Instant Data Import/Export</strong> — You own your data and can import or export it any time instantly.
                </li>
              </ol>
              <br/>
              With InstantAgencyTools, you can run structured and productive Weekly Meetings, track business with with Scorecards, build and share your Organizational Chart and communicate your business's Vision and Plan in one place.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Why should I use another tool - even if it's free?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              If your Weekly team meetings could use more focus and your team wants to get more done in the time they have, then InstantAgencyTools is right for you.
              <br/><br/>
              If you're searching for a way to make your vision and key business priorities accessible to everyone in your business, the tools can also help you.
              <br/><br/>
              With InstantAgencyTools, you can run structured and productive Weekly Meetings, track business with with Scorecards, build and share your Organizational Chart and communicate your business's Vision and Plan in one place.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Why is this free after all?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Because we want to put a smile on your face. And we want to help as many agency teams as possible to become more aligned, focused and transparent.
              <br /><br />
              We also love EOS<sup>&reg;</sup>, we believe it’s the best way to run your business and InstantAgencyTools can help run it.
              <br /><br />
              For EOS<sup>&reg;</sup>, there are two other great EOS<sup>&reg;</sup> approved software providers you can check out:
              <ol>
                <li>
                  <a
                    target="_blank"
                    href="https://www.ninety.io/"
                  >Ninety.io</a>
                  (starts at $12/month/user and scales back in volume)
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.mytractiontools.com/"
                  >MyTractionTools</a>
                  (starts at $149/month for up to 10 users)
                </li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>I’m in - how can I get started?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Welcome! You can create your first tools without logging in. Be aware that saving and sharing the tools with your team is only available once you log in.
              <br /><br />
              Log in through your Gmail or Microsoft account and create your first Vision and Plan, Organisational Chart, Weekly Meeting or Scorecard. We'll help you get started with a bunch of emails for the first 2 weeks.
              <br /><br />
              If you need a hand, contact us:
              <a
                href="mailto:support@instantagencytools.com"
              >support@instantagencytools.com</a>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>I love InstantAgencyTools - how can I thank you?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              If you love our tools and want to thank us, our ask is that you include us in your blog or other content and spread the word on Twitter, LinkedIn, Facebook. Looking forward to a mention from you!
              <br /><br />

              And if you're very curious, you can have a look at the project management suite
              <a
                target="_blank"
                href="https://teamwork.grsm.io/instantagencytools"
              >Teamwork</a>.
              To-dos sync instantly between InstantAgencyTools and Teamwork.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Can I rely on InstantAgencyTools?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Our dedicated
              <a
                target="_blank"
                href="https://teamwork.grsm.io/instantagencytools"
              >Teamwork</a>
              software development team is behind this product. We follow a bug-first mentality to ensure that InstantAgencyTools remains robust and usable at all times.
              <br /><br />

              InstantAgencyTools is built on
              <a
                target="_blank"
                href="https://firebase.google.com/"
              >Google Firebase</a>,
              which is a robust and scalable platform. Your data is yours fully, you can import or export it at any time.
              <br /><br />

              In case you need hand, let us know:
              <a
                href="mailto:support@instantagencytools.com"
              >support@instantagencytools.com</a>.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>What if I run into issues...where will I get help?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              If you ever run into any issues with our products, please reach out to
              <a
                href="mailto:support@instantagencytools.com"
              >support@instantagencytools.com</a>
              and we’ll look after you.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Where is my data stored? Who has access to it?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Your data is yours and not owned by us in any form. It’s encrypted and anonymised. Within our product team, only specific and security trained staff have access to the InstantAgencyTools database.

              <br /><br />
              We use
              <a
                target="_blank"
                href="https://firebase.google.com/"
              >Google Firebase</a>
              as the platform for InstantAgencyTools. It’s a reliable, scalable and secure platform.

              <br /><br />
              You’re be able to import/export or delete your own data instantly.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              I plan to integrate InstantAgencyTools into my existing systems - how can I go about it?
            </v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              <a
                target="_blank"
                href="https://firebase.google.com/"
              >Google Firebase</a>
              doesn’t allow any API integration as there’s no database behind it. For now, you can instantly import and export your data from InstantAgencyTools.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>You’ve built some initial tools...are you going to add to the toolset?</v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Absolutely! So far, we’ve built the foundational tools to grow your agency operations. Once you sign in, we'll keep you in the loop on future builds.

              <br /><br />
              We'd love to hear your feedback on what would bring further value to your team:
              <a
                href="mailto:support@instantagencytools.com"
              >support@instantagencytools.com</a>.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>What is EOS<sup>&reg;</sup>?</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Wow. Ok.. Um... EOS<sup>&reg;</sup> (Entrepreneurial Operating System<sup>&reg;</sup>) is the best way to run your company. It is a simple framework you can use to streamline everything - from your company vision to your people, processes and data. So you spend most of your time on the important stuff. Have a look at
              <a
                href="https://www.eosworldwide.com/"
                target="_blank"
              >www.eosworldwide.com</a>
              and the book
              <a
                href="https://www.goodreads.com/book/show/8549192-traction"
                target="_blank"
              >Traction<sup>&reg;</sup></a>.
              <br/><br/>
              We are running our teams on EOS<sup>&reg;</sup> and InstantAgencyTools helps us run it.
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>I’m struggling with the implementation of EOS<sup>&reg;</sup> in my company. What can I do?</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="eos-faqs__content">
              Fortunately, there’s a great network of fantastic Professional EOS<sup>&reg;</sup> Implementers all over the world. Have a look at the EOS<sup>&reg;</sup> World-Wide Implementers page and find one near you:
              <a
                target="_blank"
                href="https://implementer.eosworldwide.com/"
              >https://implementer.eosworldwide.com/</a>.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFAQs',
  metaInfo () {
    return {
      title: 'FAQs',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'List of commonly asked questiones and queries'
        }
      ]
    }
  }
}
</script>

<style type="scss" scoped>
h3 {
  color: #777;
}

a {
  text-decoration: none;
}

.eos-faqs__content {
  max-width: 720px;
  line-height: 1.85rem;
}
</style>
